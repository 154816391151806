import React, { useContext } from 'react'
import { Container } from '../../assets/styles'
import styled from 'styled-components'
import AuthPageContentContext from './AuthPageContext'
import DomainContext from './DomainContext'

const StyledInfoContainer = styled(Container)`
min-height: 450px;
flex-shrink: 1;
padding: 3% 0;

@media (max-width: 1049px) {
    min-height: auto;
    padding: 5% 0 10% 0;
}
`
const StyledInfoContent = styled.div`
  background: url(${props => props.$background});
  background-position: 0% 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #075E9C;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: overlay, normal;
  color: #FFF;
  width: 80%; 
  padding: 3%;

  @media (min-width: 1150px) {
    width: 80%; 
    max-width: 1410px;
    padding: 52px 322px 51.206px 323px;
  }
`
const ContentContainer = styled.div`
display: flex;
padding: 30px 20px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 14px;
flex: 1 0 0;

@media (min-width: 769px) {
    align-items: center;
}
`
const InfoTitle = styled.span`
color: #FFF
text-align: left;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 46px; /* 115% */

@media (min-width: 769px) {
    font-size: 40px;
    text-align: center;
}
`
const InfoSubtitle = styled.span`
color: #FFF
text-align: left;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 23px; /* 127.778% */

@media (min-width: 769px) {
    font-size: 18px;
    text-align: center;
  }
`
const StyledLink = styled.a`
display: flex;
flex-direction: row;
gap: 8px;
align-items: center;
padding: 10px 16px;
text-decoration: none;
color: #FFF;
border: 2px solid #FFF;
border-radius: 4px;

&:hover {
    background-color: #FFF;
    color: ${props => props.$isNcp ? '#075E9C' : '#050D42'};
}
`

const InfoPanel = () => {
    const { authPageContent } = useContext(AuthPageContentContext)
    const { isNcp } = useContext(DomainContext)

    return (
        <StyledInfoContainer>
            <StyledInfoContent
                $background={authPageContent?.info_panel_image?.filename}>
                <ContentContainer>
                    <InfoTitle>{authPageContent?.learn_more_title}</InfoTitle>
                    <InfoSubtitle>{authPageContent?.learn_more_subtitle}</InfoSubtitle>
                    <StyledLink
                        $isNcp={isNcp}
                        href={authPageContent?.learn_more_link?.url}
                        rel="noopener noreferrer">
                        <i className="fa-solid fa-chevron-right"></i>
                        {authPageContent?.learn_more_button_text}
                    </StyledLink>
                </ContentContainer>
            </StyledInfoContent>
        </StyledInfoContainer>
    )
}

export default InfoPanel