import React, { useState, useEffect, useContext, useCallback } from 'react'
import { StyledForm, InputContainer, StyledInput, InputIcon, StyledButton } from '../assets/styles'
import ReCaptcha from './common/ReCaptcha'
import useReCaptchaVerification from './utils/customHooks/useReCaptchaVerification'
import { requestResetPassword } from '../api/ncpApiMethods';
import { Alert } from './common/Alert';
import FormContainer from './common/FormContainer'
import AuthPageContentContext from './common/AuthPageContext';
import DomainContext from './common/DomainContext';
import { getErrorMessage, renderContentNode } from './utils/helpers';
import { InputErrorMessage } from './common/Alert';
import OverlayLoader from './common/OverlayLoader';
import { useLocale } from './common/LocaleContext';
import useCachedStoryblok from './utils/customHooks/useCachedStoryblok';
import styled from 'styled-components';

const InfoContainer = styled.div`
color: #706F6F;
font-size: 14px;
display: flex;
flex-direction: column;
gap: 5px;

img {
width: 250px;
}
`

const PasswordResetForm = () => {
    const { verified,
        captchaToken,
        handleVerify,
        resetCaptcha,
        resetCaptchaCheckbox } = useReCaptchaVerification()
    const { isNcp, csrfToken } = useContext(DomainContext)
    const { locale, brand } = useLocale()
    const { authPageContent, storyblokApi, cacheExpiration } = useContext(AuthPageContentContext)
    const passwordResetStatuses = useCachedStoryblok(storyblokApi, brand, locale, 'passwordResetForm', 'status-messages/password-reset-form', cacheExpiration)
    const [email, setEmail] = useState('')
    const [postal, setPostal] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [emailError, setEmailError] = useState('')
    const [postalError, setPostalError] = useState('')
    const [errorObject, setErrorObject] = useState(null)
    const [errorMessage, setErrorMessage] = useState('')
    const [recaptchaError, setRecaptchaError] = useState('')
    const [loading, setLoading] = useState(false)

    const updateErrorMessages = useCallback((error) => {
        setErrorMessage(getErrorMessage(error, authPageContent, passwordResetStatuses));
    }, [authPageContent, passwordResetStatuses])

    useEffect(() => {
        if (errorObject) {
            updateErrorMessages(errorObject)
        }
    }, [locale, authPageContent, passwordResetStatuses, errorObject, updateErrorMessages])

    const handleSubmit = async (e) => {
        e.preventDefault()
        clearFieldErrors()
        setLoading(true)
        if (!captchaToken || !verified) {
            setRecaptchaError(authPageContent?.error_verify_recaptcha)
        }
        if (!email) {
            setEmailError(authPageContent?.error_username_required)
        }
        if (!postal) {
            setPostalError(authPageContent?.error_postal_required)
        }
        if (!email || !postal) {
            setErrorMessage(authPageContent?.error_empty_fields)
        }

        try {
            const requestBody = {
                email,
                postal,
            }
            await requestResetPassword(requestBody, csrfToken, captchaToken)
            setSuccessMessage(passwordResetStatuses?.status_200_response)
            setEmail('')
            setPostal('')
        } catch (error) {
            console.error('Failed to reset password.', error)
            setErrorObject(error)
            updateErrorMessages(error)
            resetCaptcha()
            setSuccessMessage('')
        } finally {
            setLoading(false)
            resetCaptcha()
        }
    }

    const clearFieldErrors = () => {
        setEmailError('')
        setPostalError('')
        setErrorMessage('')
        setRecaptchaError('')
    }

    return (
        <>
            <FormContainer
                headerText={authPageContent?.reset_password_form_title}
            >
                {loading && (
                    <OverlayLoader
                        loading={loading} />
                )}
                <StyledForm onSubmit={handleSubmit}>
                    <InfoContainer>
                        {authPageContent?.reset_password_instructions && renderContentNode(authPageContent?.reset_password_instructions)}
                    </InfoContainer>
                    {successMessage && (
                        <Alert
                            type='success'
                            message={successMessage} />
                    )}
                    {errorMessage && (
                        <Alert
                            type='error'
                            message={errorMessage} />
                    )}
                    <InputContainer>
                        <StyledInput
                            type='text'
                            className='form-control'
                            $error={emailError}
                            name='email'
                            placeholder={authPageContent?.username_label}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <InputIcon className='fa-solid fa-user'></InputIcon>
                    </InputContainer>
                    {emailError && (
                        <InputErrorMessage type='error' message={emailError} />
                    )}
                    <InputContainer>
                        <StyledInput
                            type='text'
                            className='form-control'
                            $error={postalError}
                            name='postal'
                            placeholder={authPageContent?.zipcode_label}
                            value={postal}
                            onChange={(e) => setPostal(e.target.value)}
                        />
                        <InputIcon className='fa-solid fa-location-dot'></InputIcon>
                    </InputContainer>
                    {postalError && (
                        <InputErrorMessage type='error' message={postalError} />
                    )}
                    <ReCaptcha onVerify={handleVerify} error={recaptchaError} onReset={resetCaptchaCheckbox} />
                    <StyledButton
                        type='submit'
                        disabled={loading}
                        variant={isNcp ? 'success' : 'primary'}
                        $isNcp={isNcp}>
                        {loading ? 'Submitting...' : authPageContent?.submit_button_text}
                    </StyledButton>
                </StyledForm>
            </FormContainer>
        </>
    )
}

export default PasswordResetForm