export const API_KEY = process.env.REACT_APP_API_KEY
export async function decrypt(data, key) {
    const encoder = new TextEncoder();
    const decoder = new TextDecoder();
    let encoded = encoder.encode(atob(key));
    const algorithm = { name: "AES-CBC", length: 256, iv: new Uint8Array(16) };
    const chars = Uint8Array.from(atob(data), c => c.charCodeAt(0));
    const formatted = await window.crypto.subtle.importKey("raw", encoded, algorithm, false, ["decrypt"]);
    const decrypted = await window.crypto.subtle.decrypt(algorithm, formatted, chars);
    return decoder.decode(decrypted);
}