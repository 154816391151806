import React from 'react'
import styled from 'styled-components'
import { LoaderSvg } from './LoaderSvg'

const StyledLoader = styled.div`
z-index: 15;
position: absolute;
background: rgba(255, 255, 255, 0.4); /* Semi-transparent white */
display: flex;
justify-content: center;
align-items: center;
top: 0;
left: 0;
right: 0;
bottom: 0;
`
const OverlayLoader = ({ loading }) => {
    if (!loading) return null
    return (
        <StyledLoader>
            <LoaderSvg />
        </StyledLoader>
    )
}

export default OverlayLoader