import React, { useContext, useEffect, useMemo } from 'react'
// import LocaleContext from './LocaleContext'
import { useLocale } from './LocaleContext'
import styled from 'styled-components'
import { Dropdown } from 'react-bootstrap'
import AuthPageContentContext from './AuthPageContext'
import DomainContext from './DomainContext'
import { useNavigate, useLocation } from 'react-router-dom'
import { img } from '../utils/helpers'

const NavContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
position: sticky;
top: 0;
z-index: 1000;
padding: 10px 25px 10px 25px;
background: ${props => props.$isNcp ? '#FFF' : '#050D42'};
`
const Header = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { isNcp } = useContext(DomainContext)
    const { locale, setLocale, brand } = useLocale()
    const { authPageContent } = useContext(AuthPageContentContext)

    const filteredLocales = useMemo(() => {
        return brand === 'ncp'
            ? { 'en-us': authPageContent?.english_us_label, 'es-us': authPageContent?.spanish_us_label }
            : { 'en-ca': authPageContent?.english_ca_label, 'fr-ca': authPageContent?.french_ca_label }
    }, [brand, authPageContent])

    const handleLocaleChange = (code) => {
        setLocale(code)
        const pathSegments = location?.pathname.split('/')
        const localeSegmentIndex = pathSegments.indexOf('login') + 1
        pathSegments[localeSegmentIndex] = code
        const newPath = pathSegments.join('/')
        navigate(newPath)
    }

    useEffect(() => {
        const pathSegments = location?.pathname.split('/')
        const urlLocale = pathSegments[3]
        if (filteredLocales[urlLocale]) {
            setLocale(urlLocale)
        }
    }, [filteredLocales, setLocale, location])

    return (
        <NavContainer $isNcp={isNcp}>
            {authPageContent && authPageContent.logo && authPageContent.logo.filename && (
                <img
                    src={img(authPageContent.logo.filename)}
                    alt={authPageContent.logo.alt}
                    style={{ width: 'auto', maxHeight: '33px' }} />
            )}
            <Dropdown>
                <Dropdown.Toggle
                    id='dropdown-basic'
                    style={{ backgroundColor: 'white', color: 'black', borderColor: '#dee2e6' }}>
                    {filteredLocales[locale]}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {Object.entries(filteredLocales).map(([code, displayName]) => (
                        <Dropdown.Item key={code} onClick={() => handleLocaleChange(code)}>
                            {displayName}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </NavContainer>
    )
}

export default Header