import { useState } from 'react'

const useReCaptchaVerification = () => {
    const [verified, setVerified] = useState(false)
    const [captchaToken, setCaptchaToken] = useState('')
    const [resetCaptchaCheckbox, setResetCaptchaCheckbox] = useState(false)

    const handleVerify = async (token) => {
        if (token) {
            setVerified(true)
            setCaptchaToken(token)
            setResetCaptchaCheckbox(false)
        } else {
            setVerified(false)
            setCaptchaToken('')
            setResetCaptchaCheckbox(true)
        }
    }

    const resetCaptcha = () => {
        setVerified(false)
        setCaptchaToken('')
        setResetCaptchaCheckbox(true)
    }

    return { verified, captchaToken, handleVerify, resetCaptcha, resetCaptchaCheckbox, setResetCaptchaCheckbox }
}

export default useReCaptchaVerification