import React, { useContext } from 'react';
import DomainContext from './common/DomainContext';
import AuthPageContentContext from './common/AuthPageContext';
import { renderContentNode } from './utils/helpers';
import { MessageContainer } from '../assets/styles';

const LoginTroubleshoot = () => {
    const { isNcp } = useContext(DomainContext)
    const { authPageContent } = useContext(AuthPageContentContext)

    return (
        <MessageContainer $isNcp={isNcp}>
            {authPageContent?.forgot_username_message && renderContentNode(authPageContent?.forgot_username_message)}
        </MessageContainer>
    )
};

export default LoginTroubleshoot;
