import { useEffect, useState } from 'react'

const useCachedStoryblok = (storyblokApi, brand, locale, storyName, path, cacheExpiration) => {
    const [data, setData] = useState(null)

    useEffect(() => {
        const cacheKey = `${brand}-${storyName}-${locale}`
        const fetchData = async () => {
            try {
                if (!storyblokApi) return
                const response = await storyblokApi.get(`cdn/stories/${brand}/${locale}/${path}`)
                const newData = response?.data?.story?.content;
                const cacheValue = {
                    timestamp: new Date().getTime(),
                    data: newData
                }
                localStorage.setItem(cacheKey, JSON.stringify(cacheValue))
                setData(newData)
            } catch (error) {
                console.error(`Error fetching ${storyName} story: `, error)
            }
        }
        const getDataFromCache = () => {
            const cachedDataString = localStorage.getItem(cacheKey)
            if (cachedDataString) {
                const cachedData = JSON.parse(cachedDataString)
                const now = new Date().getTime()
                if (now - cachedData.timestamp < cacheExpiration) {
                    setData(cachedData.data)
                } else {
                    fetchData()
                }
            } else {
                fetchData()
            }
        }
        getDataFromCache()

    }, [storyblokApi, storyName, cacheExpiration, brand, locale, path])

    return data
}

export default useCachedStoryblok