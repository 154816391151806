import styled from "styled-components";
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
flex-shrink: 1;
width: 100%;
min-width: 375px;
`
// height: inherit;
export const StyledForm = styled.form`
display: flex;
padding: 20px;;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 10px;
align-self: stretch;
background: #FFF;
width: 100%;
z-index: 10;
`
export const StyledHeader = styled.div`
background: rgba(255, 240, 240, 0.23);
backdrop-filter: blur(2px);
display: flex;
padding: 14px 0px;
justify-content: center;
align-items: center;
gap: 9px;
align-self: stretch;
color: #FFFFFF;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
`
export const StyledInput = styled.input`
border: ${props => props.$error ? '1px solid #C12125' : 'none'};
border-radius: ${props => props.$error ? '1px' : '0'};
width: 100%;
background-color: #E6E6E6;
padding: 9px 30px 9px 9px;
z-index: -1;
`
export const StyledLink = styled(Link)`
color: var(--Neutral-Grey-80, #636363);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-decoration: none;
`
export const StyledInputGroup = styled.div`
display: flex;
padding: 20px 20px 10px 20px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 9.087px;
align-self: stretch;
`
export const InputContainer = styled.div`
position: relative;
z-index: 10;
width: 100%;
`
export const InputIcon = styled.i`
position: absolute;
top: 50%;
right: 20px;
transform: translateY(-50%);
`
export const StyledButton = styled(Button)`
display: flex;
padding: 12px 32px;
justify-content: center;
align-items: center;
align-self: stretch;
border-radius: 2px;
background: ${props => props.$isNcp ? '#1D9B81' : '#3A71EE'};
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 22.4px */
border: none;
`

export const MessageContainer = styled.div`
color: #FFF !important;
display: flex;
flex-direction: column;
gap: 10px;
width: 75%;
padding: 30px 0px;

@media (max-width: 1049px) {
display: flex;
width:90%;
padding: 30px 20px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 14px;
flex-shrink: 0;
color: #333 !important;
font-size: 16px;
line-height: 23px;
border-top: 3px solid ${props => props.$isNcp ? '#075E9C' : '#050D42'};
background: rgba(255, 255, 255, 0.70);

h1, h2, h3, h4, h5, h6 { 
    color: ${props => props.$isNcp ? '#075E9C' : '#050D42'}; /* Makes color same as border-top */
}
}
`