import React, { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import styled from 'styled-components'
import { InputErrorMessage } from './Alert'
import { decrypt, API_KEY } from '../utils/decrypt'
import { useLocale } from './LocaleContext'

const RecaptchaContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 9.087px;
align-self: stretch;
`
const StyledReCAPTCHA = styled(ReCAPTCHA)`
border: ${props => props.error ? '1px solid #C12125' : 'none'};
`

const ReCaptcha = ({ onVerify, error, onReset }) => {
    const [decryptedKey, setDecryptedKey] = useState('')
    const { locale } = useLocale()
    const recaptchaRef = useRef(null)
    const [recaptchaLang, setRecaptchaLang] = useState('en')

    const handleChange = token => {
        if (token) {
            onVerify(token)
        } else {
            onVerify(false)
        }
    }

    const localeToRecaptchaLanguage = (lang) => {
        const localeMap = {
            'en-us': 'en',
            'es-us': 'es',
            'en-ca': 'en-GB',
            'fr-ca': 'fr-CA',
        }
        return localeMap[lang]
    }

    useEffect(() => {
        const encryptedRecaptchaSiteKey = window.recaptchaSiteKey

        decrypt(encryptedRecaptchaSiteKey, API_KEY)
            .then((decrypted) => {
                setDecryptedKey(decrypted)
            })
            .catch((error) => console.error('Error decrypting key: ', error))
    }, [])

    useEffect(() => {
        if (onReset && recaptchaRef.current) {
            recaptchaRef.current.reset();
        }
    }, [onReset])

    useEffect(() => {
        const recaptchaLangCode = localeToRecaptchaLanguage(locale)
        setRecaptchaLang(recaptchaLangCode)
    }, [locale])

    return (
        <RecaptchaContainer>
            {decryptedKey &&
                <StyledReCAPTCHA
                    ref={recaptchaRef}
                    error={error}
                    sitekey={decryptedKey}
                    onChange={handleChange}
                    onExpired={() => onVerify(false)}
                    hl={recaptchaLang}
                    key={recaptchaLang}
                />
            }
            {error && (
                <InputErrorMessage type='error' message={error} />
            )}
        </RecaptchaContainer>
    )
}

export default ReCaptcha