import React from 'react'
import styled from 'styled-components'
import { CheckCircleFill, ExclamationCircleFill, InfoCircleFill } from '../../assets/icons';

const AlertContainter = styled.div`
    padding: 20px;
    background-color: ${(props) => props.$backgroundColor};
    border-radius: 2px;
    border: 1px solid ${(props) => props.$backgroundColor};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
    position: relative;
    width: 100%;
    margin: 0;
    color: ${props => props.color}
`

const ErrorMessageContainer = styled.div`
display: flex;
flex-direction: row;
gap: 5px;
color: ${props => props.$color};
font-size: 13px;
font-weight: 500;
`
const types = {
    success: { backgroundColor: 'success', icon: <CheckCircleFill /> },
    error: {
        backgroundColor: 'danger', alertColor: '#911D1F', inputColor: '#C12125', icon: <i className="fa-solid fa-circle-exclamation"></i>
    },
    warning: { backgroundColor: 'warning', icon: <ExclamationCircleFill /> },
    info: { backgroundColor: 'primary', icon: <InfoCircleFill /> },
};

export const Alert = ({ type, message }) => {
    const alertType = types[type] || types.info

    return (
        <AlertContainter
            className={`alert alert-${alertType.backgroundColor}`}
            color={alertType.color}
            role="alert">
            <span>{alertType.icon}</span>
            <span>
                {message}
            </span>
        </AlertContainter >
    )
}

export const InputErrorMessage = ({ type, message }) => {
    const alertType = types[type] || types.info
    return (
        <ErrorMessageContainer $color={alertType.inputColor}>
            <span>{alertType.icon}</span>
            <span>{message}</span>
        </ErrorMessageContainer>
    )
};