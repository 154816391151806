import React from 'react'
import { Container, StyledHeader } from '../../assets/styles';
import styled from 'styled-components'
const StyledFormContainer = styled(Container)`
@media (max-width: 628px) {
    width: 90%;
}
@media (max-width: 1049px) {
    position: absolute;
    top: -9.5%;
    width: 75%; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    box-sizing: border-box;
    }
`
// position: absolute;
// overflow: auto;
// bottom: 5%;
const FormContainer = ({ children, headerIcon = '', headerText }) => {
    return (
        <StyledFormContainer>
            <StyledHeader>
                {headerIcon && <i className={headerIcon}></i>}
                <span>{headerText}</span>
            </StyledHeader>
            {children}
        </StyledFormContainer>
    )
}

export default FormContainer