import React, { useContext } from 'react'
import styled from 'styled-components'
import AuthPageContentContext from './AuthPageContext'
import DomainContext from './DomainContext';
import { img } from '../utils/helpers';

const FooterContainer = styled.footer`
border-top: 1px solid #ddd;
margin-top: 60px;
padding: 30px 0;
display: flex;
flex-direction: column;
align-items: center;
gap: 28px;
background: ${props => props.$isNcp ? '#333' : '#050D42'};
padding: 18px 0px;
font-family: Poppins;
overflow: hidden;
`;

const FooterInner = styled.div`
  max-width: 1410px;
  padding: 0 5%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 18px;
  padding-bottom: 0px;
  text-align: center;

  @media (min-width: 1410px) {
    padding: 0;
    width: 90%;
  }

  nav {
    margin-bottom: 28px;

    a {
      transition: all 0.15s;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: #2d6df6;

        svg path {
          fill: #2d6df6;
        }
      }
    }
  }

  .app-store-nav a {
    margin-right: 5px;
    margin-left: 5px;

    img {
      max-height: 32px;
      border: 1px solid #a3a3a2;
      border-radius: 4px;
    }

  }

  .footer-meta-nav a {
    color: #fff;
    text-align: center;
    font-size: 14.54px;
    font-style: normal;
    font-weight: 400;
    line-height: 32.442px;
    transition: all 0.15s linear;

    &:hover {
      color: #2c95ff;
      text-decoration: underline;
    }

    & + a:before {
      content: '|';
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .footer-logos-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    a img {
      max-height: 38px;
    }

    a + a {
      margin-left: 24px;
    }

    a:first-of-type img {
      max-height: 20px;
    }

    a:last-of-type img {
      max-height: 30px;
    }
  }
   .footer-copyright-nav {
      color: #FFF;
      text-align: center;
      font-size: 14.54px;
      font-style: normal;
      font-weight: 400;
      line-height: 32.442px;
    }
`;

const Footer = () => {
  const { footerContent } = useContext(AuthPageContentContext)
  const { isNcp } = useContext(DomainContext)

  const currentYear = new Date().getFullYear()

  return (
    <FooterContainer $isNcp={isNcp}>
      <FooterInner>
        {footerContent && footerContent?.footer_items?.map((item, index) => (
          <nav key={`footer-row-${index}`} className={`${item?.name}-nav`}>
            {item?.name === 'footer-copyright' && item?.items?.length > 0
              && item?.items?.[0]?.component === 'Dynamic Text' ? (
              <div>
                &copy; {currentYear} {item?.items?.[0].text}
              </div>
            ) : (
              item?.items?.map((footerItem, i) => (
                <a key={`footer-item-${i}`} href={footerItem?.link?.url} target='_blank' rel="noreferrer">
                  {footerItem?.image ? (
                    <img
                      src={img(footerItem?.image?.filename)}
                      alt={footerItem?.image?.name}
                    />
                  ) : (
                    footerItem?.link_text
                  )}
                </a>
              ))
            )}
          </nav>
        ))}
      </FooterInner>
    </FooterContainer>
  )
}

export default Footer