import React, { createContext, useContext, useState } from 'react'

const StoryblokContext = createContext()

export const useStoryblok = () => useContext(StoryblokContext)

export const StoryblokProvider = ({ children }) => {
    const [storyblokApi, setStoryblokApi] = useState()

    return (
        <StoryblokContext.Provider value={{ storyblokApi, setStoryblokApi }}>
            {children}
        </StoryblokContext.Provider>
    )
}