import React, { createContext, useState, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const LocaleContext = createContext({
    locale: 'en-us',
    setLocale: () => { }
})

const supportedLocales = {
    ncp: ['en-us', 'es-us'],
    homescan: ['en-ca', 'fr-ca']
}

export const LocaleProvider = ({ children }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [locale, setLocale] = useState(() => {
        const pathLocale = window.location.pathname.split('/')[3];
        return pathLocale || 'en-us';
    })
    const [contextPath, setContextPath] = useState('/ncpweb')
    const [brand, setBrand] = useState('')

    useEffect(() => {
        const contextPathValue = window.contextPath
        setContextPath(contextPathValue)

        const brandValue = window.brand
        const lowerCaseBrand = brandValue?.toLowerCase()

        setBrand(lowerCaseBrand)

        const storageData = JSON.parse(localStorage.getItem('appData')) || {}
        storageData.brand = lowerCaseBrand

        localStorage.setItem('appData', JSON.stringify(storageData))

    }, [])

    useEffect(() => {
        const urlLocale = location.pathname.split('/')[3];
        const appData = JSON.parse(localStorage.getItem('appData'));
        const currentBrand = appData ? appData.brand : null;

        if (currentBrand && supportedLocales[currentBrand]) {
            if (supportedLocales[currentBrand].includes(urlLocale)) {
                if (locale !== urlLocale) {
                    setLocale(urlLocale);
                    console.log(`Locale set and navigating to: ${urlLocale}`)
                    navigate(`${contextPath}/login/${urlLocale}`)
                }
            } else {
                const defaultLocale = supportedLocales[currentBrand][0]
                setLocale(defaultLocale)
                console.log(`Unsupported locale. Navigating to default locale for brand: ${defaultLocale}`);
                navigate(`${contextPath}/login/${defaultLocale}`)
            }
        } else {
            console.error('Brand is not recognized or no brand specified. Falling back to default locale.');
            if (locale !== 'en-us') {
                setLocale('en-us');
                navigate(`${contextPath}/login/en-us`)
            }
        }
    }, [location.pathname, locale, navigate, contextPath]);

    return (
        <LocaleContext.Provider value={{ locale, setLocale, brand, contextPath }}>
            {children}
        </LocaleContext.Provider>
    )
}

export const useLocale = () => useContext(LocaleContext)

export default LocaleContext
