import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { StyledForm, InputContainer, StyledInput, StyledButton, InputIcon } from '../assets/styles'
import ReCaptcha from './common/ReCaptcha'
import useReCaptchaVerification from './utils/customHooks/useReCaptchaVerification'
import { resetPassword } from '../api/ncpApiMethods'
import { Alert } from './common/Alert'
import { useParams } from 'react-router'
import FormContainer from './common/FormContainer'
import AuthPageContentContext from './common/AuthPageContext'
import DomainContext from './common/DomainContext'
import { useLocale } from './common/LocaleContext'
import { getErrorMessage } from './utils/helpers'
import OverlayLoader from './common/OverlayLoader'
import useCachedStoryblok from './utils/customHooks/useCachedStoryblok'

const NewPasswordForm = () => {
    const { isNcp, csrfToken } = useContext(DomainContext)
    const navigate = useNavigate()
    const { locale, brand, contextPath } = useLocale()
    const { authPageContent, storyblokApi, cacheExpiration } = useContext(AuthPageContentContext)
    const newPasswordStatuses = useCachedStoryblok(storyblokApi, brand, locale, 'newPasswordForm', 'status-messages/new-password-form', cacheExpiration)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const { token } = useParams();
    const [successMessage, setSuccessMessage] = useState('')
    const [errorObject, setErrorObject] = useState(null)
    const [errorMessage, setErrorMessage] = useState('')
    const { verified,
        captchaToken,
        handleVerify,
        resetCaptcha,
        resetCaptchaCheckbox } = useReCaptchaVerification()
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)

    const updateErrorMessages = useCallback((error) => {
        setErrorMessage(getErrorMessage(error, authPageContent, newPasswordStatuses));
    }, [authPageContent, newPasswordStatuses])

    useEffect(() => {
        if (errorObject) {
            updateErrorMessages(errorObject)
        }
    }, [locale, authPageContent, newPasswordStatuses, errorObject, updateErrorMessages])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        //Submit new password
        if (!verified || !captchaToken) {
            setErrorMessage(authPageContent?.error_verify_recaptcha)
            return
        }
        if (password !== confirmPassword) {
            setErrorMessage(authPageContent?.error_password_mismatch)
            setLoading(false)
            return
        }

        try {
            const requestBody = {
                new_password: password,
            }
            await resetPassword(requestBody, csrfToken, token, captchaToken)
            setSuccessMessage(newPasswordStatuses?.status_200_response)
            setErrorMessage('')
            setTimeout(() => {
                navigate(`${contextPath}/login/${locale}`)
            }, 2000)
        } catch (error) {
            console.error('Error resetting password: ', error)
            setErrorObject(error)
            updateErrorMessages(error)
            resetCaptcha()
            setSuccessMessage('')
        } finally {
            clearPasswordFields()
            setLoading(false)
        }
    }

    const clearPasswordFields = () => {
        setPassword('')
        setConfirmPassword('')
        resetCaptcha()
    }

    const togglePassword = () => {
        setShowPassword(!showPassword)
    }
    return (
        <FormContainer
            headerText={authPageContent?.new_password_form_title}
        >
            {loading && (
                <OverlayLoader
                    loading={loading} />
            )}
            <StyledForm onSubmit={handleSubmit}>
                {successMessage && (
                    <Alert
                        type='success'
                        message={successMessage} />
                )}
                {errorMessage && (
                    <Alert
                        type='error'
                        message={errorMessage} />
                )}
                <InputContainer>
                    <StyledInput
                        type={showPassword ? 'text' : 'password'}
                        name='password'
                        className='form-control'
                        placeholder={authPageContent?.password_label}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputIcon onClick={togglePassword} className={showPassword ? 'fa-solid fa-lock-open' : 'fa-solid fa-lock'} ></InputIcon>
                </InputContainer>
                <InputContainer>
                    <StyledInput
                        type={showPassword ? 'text' : 'password'}
                        name='confirmPassword'
                        className='form-control'
                        placeholder={authPageContent?.confirm_password_label}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </InputContainer>
                <ReCaptcha onVerify={handleVerify} onReset={resetCaptchaCheckbox} />
                <StyledButton
                    type='submit'
                    disabled={loading}
                    variant={isNcp ? 'success' : 'primary'}
                    $isNcp={isNcp}>
                    {loading ? 'Submitting...' : authPageContent?.submit_button_text}
                </StyledButton>
            </StyledForm>
        </FormContainer>
    )
}

export default NewPasswordForm