import React, { useContext } from 'react'
import AuthPageContentContext from './common/AuthPageContext'
import { MessageContainer } from '../assets/styles'
import DomainContext from './common/DomainContext'
import { renderContentNode } from './utils/helpers'

const NotFoundPage = () => {
    const { isNcp } = useContext(DomainContext)
    const { authPageContent } = useContext(AuthPageContentContext)
    return (
        <MessageContainer $isNcp={isNcp}>
            {authPageContent?.not_found_instructions && renderContentNode(authPageContent?.not_found_instructions)}
        </MessageContainer>
    )
}

export default NotFoundPage