import React, { useState, useContext, useEffect, useCallback } from 'react';
import {
    StyledForm,
    StyledButton,
    StyledInput,
    InputContainer,
    InputIcon,
    StyledLink
} from '../assets/styles';
import ReCaptcha from './common/ReCaptcha';
import useReCaptchaVerification from './utils/customHooks/useReCaptchaVerification';
import { loginUser } from '../api/ncpApiMethods';
import { useLocale } from './common/LocaleContext';
import styled from 'styled-components'
import AuthPageContentContext from './common/AuthPageContext';
import FormContainer from './common/FormContainer';
import { Alert, InputErrorMessage } from './common/Alert';
import DomainContext from './common/DomainContext';
import { getErrorMessage } from './utils/helpers';
import OverlayLoader from './common/OverlayLoader';
import useCachedStoryblok from './utils/customHooks/useCachedStoryblok';
import { useNavigate } from 'react-router-dom';

const RegisterContainer = styled.div`
color: var(--Neutral-Grey-80, #636363);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
`
const LoginForm = () => {
    let navigate = useNavigate()
    const { returnTo, isNcp, instanceSubDomain, csrfToken } = useContext(DomainContext)
    const { locale, brand, contextPath } = useLocale()
    const { authPageContent, storyblokApi, cacheExpiration } = useContext(AuthPageContentContext)
    const loginStatuses = useCachedStoryblok(storyblokApi, brand, locale, 'loginForm', 'status-messages/login-form', cacheExpiration)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const { verified,
        captchaToken,
        handleVerify,
        resetCaptcha,
        resetCaptchaCheckbox } = useReCaptchaVerification()
    const [showPassword, setShowPassword] = useState(false)
    const [usernameError, setUsernameError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [recaptchaError, setRecaptchaError] = useState('')
    const [errorObject, setErrorObject] = useState(null)
    const [loginError, setLoginError] = useState('')
    const [loading, setLoading] = useState(false)
    const [loginAttempts, setLoginAttempts] = useState(0)

    const updateErrorMessages = useCallback((error) => {
        setLoginError(getErrorMessage(error, authPageContent, loginStatuses));
    }, [authPageContent, loginStatuses])

    useEffect(() => {
        if (errorObject) {
            updateErrorMessages(errorObject)
        }
    }, [locale, authPageContent, loginStatuses, errorObject, updateErrorMessages])

    const processLogin = async () => {
        const updatedReturnTo = returnTo.replace(/\/[a-z]{2}-[a-z]{2}$|\/[a-z]{2}$/i, `/${locale}`)
        const requestBody = {
            username,
            password,
            captcha_token: captchaToken
        }
        try {
            await loginUser(requestBody, csrfToken, updatedReturnTo, captchaToken, instanceSubDomain)
            setLoginAttempts(0)
        } catch (error) {
            console.error('Error logging in', error)
            setErrorObject(error)
            setLoginError(error?.response?.data?.message)
            setLoginError(getErrorMessage(error, authPageContent, loginStatuses))
            resetCaptcha()
            const newAttempts = loginAttempts + 1
            setLoginAttempts(newAttempts)
            if (newAttempts >= 3) {
                navigate(`${contextPath}/login/${locale}/resets/passwords`)
            }
        } finally {
            clearErrors()
            setLoading(false)
        }
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setLoading(true)

        clearErrors()

        let hasError = false
        if (!username || !password || !captchaToken || !verified) {
            setUsernameError(!username ? authPageContent?.error_username_required : '')
            setPasswordError(!password ? authPageContent?.error_password_required : '')
            setRecaptchaError((!captchaToken || !verified) ? authPageContent?.error_verify_recaptcha : '')
            setLoginError(authPageContent?.error_empty_fields)
            hasError = true
        }

        if (hasError) {
            setLoading(false)
            return
        }

        processLogin()
    }

    const togglePassword = () => {
        setShowPassword(!showPassword)
    }

    const clearErrors = () => {
        setLoginError('')
        setUsernameError('')
        setPasswordError('')
        setRecaptchaError('')
    }

    return (
        <>
            <FormContainer
                headerIcon='fa-solid fa-right-to-bracket'
                headerText={authPageContent?.login_label}
            >
                {loading && (
                    <OverlayLoader
                        loading={loading} />
                )}
                <StyledForm onSubmit={handleSubmit}>
                    {loginError && (
                        <Alert type='error' message={loginError} />
                    )}
                    <InputContainer>
                        <StyledInput
                            type='text'
                            className='form-control'
                            name='username'
                            $error={usernameError}
                            placeholder={authPageContent?.username_label}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <InputIcon className='fa-solid fa-user'></InputIcon>
                    </InputContainer>
                    <StyledLink to={`${contextPath}/login/${locale}/troubleshoot`}>{authPageContent?.forgot_username_text}</StyledLink>
                    {usernameError && (
                        <InputErrorMessage type='error' message={usernameError} />
                    )}
                    <InputContainer>
                        <StyledInput
                            type={showPassword ? 'text' : 'password'}
                            className='form-control'
                            name='password'
                            $error={passwordError}
                            placeholder={authPageContent?.password_label}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <InputIcon onClick={togglePassword} className={showPassword ? 'fa-solid fa-lock-open' : 'fa-solid fa-lock'} ></InputIcon>
                    </InputContainer>
                    <StyledLink to={`${contextPath}/login/${locale}/resets/passwords`}>{authPageContent?.forgot_password_text}</StyledLink>
                    {passwordError && (
                        <InputErrorMessage type='error' message={passwordError} />
                    )}
                    <ReCaptcha onVerify={handleVerify} error={recaptchaError} onReset={resetCaptchaCheckbox} />
                    <StyledButton
                        type='submit'
                        disabled={loading}
                        variant={isNcp ? 'success' : 'primary'}
                        $isNcp={isNcp}>{loading ? 'Submitting...' : authPageContent?.submit_button_text}</StyledButton>
                    <RegisterContainer>
                        <span>{authPageContent?.register_prompt_text} </span>
                        <StyledLink
                            to={authPageContent?.register_link?.url}
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ textDecoration: 'underline' }}>
                            {authPageContent?.register_link_text}
                        </StyledLink>
                    </RegisterContainer>
                </StyledForm>
            </FormContainer>
        </>
    )
}

export default LoginForm